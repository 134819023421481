const divideArray = <T>(arr: T[]): T[][] => {
    // calc length one part

    let partLength: number = Math.ceil(arr.length / 3);
    if (arr.length % 3 === 1) partLength = Math.floor(arr.length / 3);
    // divide Array to three parts
    const part1: T[] = arr.slice(0, partLength);
    const part2: T[] = arr.slice(partLength, partLength * 2);
    const part3: T[] = arr.slice(partLength * 2);

    return arr.length === 7 ? [part2, part3, part1] : arr.length % 3 !== 1 ? [part1, part2, part3] : [part3, part2, part1];
};
export default divideArray;
