import { useEffect, useState } from 'react';

const useMobileScreen = () => {
    const [activeMobile, setActiveMobile] = useState(false);
    useEffect(() => {
        const handleMobileScreen = () => {
            if (typeof window !== 'undefined') {
                const isMobileScreen = window.matchMedia('(max-width: 767px)').matches;

                if (isMobileScreen) {
                    setActiveMobile(true);
                } else {
                    setActiveMobile(false);
                }
            }
        };

        handleMobileScreen();

        window.addEventListener('resize', handleMobileScreen);

        return () => {
            window.removeEventListener('resize', handleMobileScreen);
        };
    }, []);
    return { activeMobile }
};

export default useMobileScreen;
