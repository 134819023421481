import Link from "next/link";

type Props = {
    text: string;
    link: string;
    newClass?: string;
};

function OutLineLink({ text, link, newClass }: Props) {
    return (
        <Link prefetch={false} href={`${link}`} className={`outline-common-btn ${newClass}`}>
            {text}
        </Link>
    );
}

export default OutLineLink;
