import CourseCardNoLink from "./CourseCardNoLinK";
import CardContent from "./cardContent/CardContent";

type Props = {
    forwardRef?: any;
    image: string;
    title: string;
    chapters: string;
    hours: string;
    averageRatings: number;
    price: string;
    priceAfterDiscount: string;
    category: string;
    newClass?: string;
    id?: string;
    isFree: boolean;
    myCourses?: boolean;
    isMyOwn?: boolean;
    myCoursePage?: boolean;
};

function LargeCoursesCard({
    id,
    forwardRef,
    image,
    title,
    chapters,
    hours,
    averageRatings,
    price,
    priceAfterDiscount,
    category,
    newClass,
    isFree,
    isMyOwn,
    myCoursePage
}: Props) {
    return (
        <div className="larg-courses-card">
            <CourseCardNoLink
                id={id}
                forwardRef={forwardRef}
                image={image}
                category={category}
                newClass={newClass}
            >
                <CardContent
                    id={id}
                    title={title}
                    chapters={chapters}
                    hours={hours}
                    averageRatings={averageRatings}
                    price={price}
                    priceAfterDiscount={priceAfterDiscount}
                    isFree={isFree}
                    isMyOwn={isMyOwn}
                    myCoursePage={myCoursePage}
                />
            </CourseCardNoLink>
        </div>
    );
}

export default LargeCoursesCard;