import { apiCategoriesSlice } from "@/redux/app/api/apiSlice";

export const categoriesApiSlice = apiCategoriesSlice.injectEndpoints({
    endpoints: (builder) => ({
        // anonymous
        getAllCategory: builder.query({
            query: (params) => ({
                url: `/api/v1/categories`,
                params: { ...params },
            }),
            providesTags: ["Categories"],
        }),
        // admin
        getListCategory: builder.query({
            query: (params) => ({
                url: `/api/v1/dashboard/categories`,
                params: { ...params },
            }),
            providesTags: ["Categories"],
        }),
        getSpecificCategory: builder.query({
            query: (categoryId) => ({
                url: `/api/v1/dashboard/categories/${categoryId}`,
            }),
            providesTags: ["Categories"],
        }),
        getStatisticsCategories: builder.query({
            query: (params) => ({
                url: `/api/v1/dashboard/statistics/categories`,
                params: { ...params },
            }),
            providesTags: ["Categories"],
        }),
        createCategory: builder.mutation({
            query: (categoryItem) => ({
                url: "/api/v1/dashboard/categories",
                method: "POST",
                body: { ...categoryItem },
            }),
            invalidatesTags: ["Categories"],
        }),
        updateCategory: builder.mutation({
            query: ({ categoryId, categoryItem }) => ({
                url: `/api/v1/dashboard/categories/${categoryId}`,
                method: "PUT",
                body: { ...categoryItem },
            }),
            invalidatesTags: ["Categories"],
        }),
        updateImageCategorySuccess: builder.mutation({
            query: ({ categoryId, categoryItem }) => ({
                url: `/api/v1/dashboard/categories/${categoryId}/success-upload-thumbnail`,
                method: "PUT",
                body: { ...categoryItem },
            }),
            invalidatesTags: ["Categories"],
        }),
        deleteCategory: builder.mutation({
            query: (categoryId) => ({
                url: `/api/v1/dashboard/categories/${categoryId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Categories"],
        }),
        deleteCategories: builder.mutation({
            query: (categoriesIds) => ({
                url: `/api/v1/dashboard/categories`,
                method: "DELETE",
                body: { categoriesIds },
            }),
            invalidatesTags: ["Categories"],
        }),
    }),
});

export const {
    useGetAllCategoryQuery,
    useLazyGetAllCategoryQuery,
    useGetListCategoryQuery,
    useGetSpecificCategoryQuery,
    useGetStatisticsCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useUpdateImageCategorySuccessMutation,
    useDeleteCategoryMutation,
    useDeleteCategoriesMutation,
} = categoriesApiSlice;
