"use client"
import SolidLink from "@/components/UI/common/buttonsOrLinks/SolidLink";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image from "next/image";

type Props = {
    title: string;
    description: string;
    image: StaticImport;
    id: string;
};

function CategoriesItem({ title, description, image, id }: Props) {
    return (
        <div className="categories-item">
            <div className="categories-item-left image-hand">
                <Image
                    priority
                    src={image}
                    alt="categories item"
                    width={500}
                    height={400}
                />
            </div>
            <div className="categories-item-right">
                <h3 className={"categories-item-title"}>{title}</h3>
                <p className={"categories-item-description"}>{description}</p>
                <SolidLink
                    link={`/courses/${id}`}
                    text={"المزيد"}
                    newClass={"categories-item-link"}
                />
            </div>
        </div>
    );
}

export default CategoriesItem;
