"use client"
import TitleSection from "@/components/UI/common/titles/TitleSection";
import CategoriesItem from "./units/CategoriesItem";
import categoriesDemoImage from "@/../public/images/categoriesDemoImage.webp";
import useCategoriesSection from "@/hooks/pages/home/sections/categoriesSection/useCategoriesSection";
import ServerError from "@/components/UI/common/textError/ServerError";
import LoadingEffect from "@/components/UI/common/loading/LoadingEffect";

function CategoriesSection() {
    const { categoriesItems, error, isLoading } = useCategoriesSection();

    return (
        <section id={"categories-section"}>
            <div className="container">
                <TitleSection
                    title={"الأقسام الرئيسية"}
                    newClass={"categories-title"}
                />
                <div className="categories-box">
                    {isLoading && <LoadingEffect />}
                    {error && <ServerError errServerMsg={error} />}
                    {Array.isArray(categoriesItems) && categoriesItems.map((el: any, inx: number) => (
                        <CategoriesItem
                            key={el?.id + inx}
                            id={el?.id}
                            title={el?.name}
                            description={el?.description}
                            image={el?.thumbnailUrl || categoriesDemoImage}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CategoriesSection;
