"use client"
import { FaBookReader } from "react-icons/fa";
import ReactStars from "@/components/UI/common/reactStare/ReactStars";
import SolidLink from "../../buttonsOrLinks/SolidLink";
import Link from "next/link";
import useAddToCart from "@/hooks/pages/dashbord/user/userCartSections/useAddToCart";
import { checkPriceNotZero } from "@/utils/country/getPrice";
import useDataOrderSection from "@/hooks/pages/courses/orderPageSections/orderSection/useDataOrderSection";
import { checkInclude } from "@/utils/checkInclude/checkInclude";
import { useEffect, useState } from "react";
import useRemoveCart from "@/hooks/pages/dashbord/user/userCartSections/useRemoveCart";
import { useAppSelector } from "@/redux/app/hooks";
import useDataPurchase from "@/hooks/pages/courses/coursePageSections/useDataPurchase";
import useAddToMyCourses from "@/hooks/pages/dashbord/user/userCartSections/useAddToMyCourses";


type Props = {
    id: string | undefined;
    title: string;
    chapters: string;
    hours: string;
    averageRatings: number;
    price: string;
    priceAfterDiscount: string;
    isFree: boolean;
    isMyOwn?: boolean;
    myCoursePage?: boolean;
};

const CardContent = (
    {
        id,
        title,
        chapters,
        hours,
        averageRatings,
        price,
        priceAfterDiscount,
        isFree,
        isMyOwn,
        myCoursePage
    }: Props
) => {

    const accessToken = useAppSelector(store => store.auth.accessToken);
    const [isAdd, SetIsAdd] = useState(false);
    const { handleAddToCart } = useAddToCart(SetIsAdd);
    const { handleAddToMyCourses } = useAddToMyCourses();

    const { handleRemoveCart } = useRemoveCart(SetIsAdd);
    const { cartData } = useDataOrderSection();
    useEffect(() => {
        accessToken && SetIsAdd(checkInclude(id, cartData));
    }, [id, cartData, accessToken]);

    const [isPurchase, SetIsPurchase] = useState(false);
    const { purchaseData } = useDataPurchase();
    useEffect(() => {
        accessToken && SetIsPurchase(checkInclude(id, purchaseData));
    }, [id, purchaseData, accessToken]);

    return (
        <div className="card-content" dir="rtl">
            <Link prefetch={false} href={`/course/${id}`}>
                <div className="card-middle">
                    <div className="card-middle-top">
                        <h3 title={title}>{title}</h3>
                        <p>دكتور محمد رمضان</p>
                    </div>
                    <div className="card-middle-bottom"></div>
                </div>
            </Link>
            <div className="card-bottom">
                <Link prefetch={false} href={`/course/${id}`}>
                    <div className="card-bottom-top">
                        <div className="card-bottom-top-right">
                            <FaBookReader className="card-icon" />
                            <span className="cart-numbers">{chapters} فصول</span>
                        </div>
                    </div>
                    <div className="card-bottom-bottom">
                        <div className="card-bottom-bottom-right">
                            <ReactStars
                                value={Number(averageRatings)}
                                edit={false}
                                count={5}
                                size={24}
                                color1={"#a3a2a2"}
                                color2={"#ffd700"}
                                className={"rating-stars-icons"}
                            />
                            <div className="rating-number">{averageRatings}</div>
                        </div>
                        {!myCoursePage ?
                            <div className="card-bottom-bottom-left">
                                <div className="price-discount">
                                    {isFree ? "مجانا" : checkPriceNotZero(priceAfterDiscount) ? priceAfterDiscount : null}
                                </div>
                                <div className="price">{price}</div>
                            </div>
                            : <p className="rating-mycourse">تقييم الدورة</p>
                        }
                    </div>
                </Link>
                <div className="btns-main-card">
                    {(isPurchase || isMyOwn) && <SolidLink text={"اذهب للدورة التعليمية"} link={`/course/${id}`} newClass={"btn-purchase-course"} />}
                    {isFree && isMyOwn !== true && !isPurchase && <SolidLink text={"أضف الدورة إلي دوراتي"} newClass={"btn-purchase-course"} onHandleClick={() => handleAddToMyCourses(id)} />}
                    {!isPurchase && isFree !== true && isMyOwn !== true && <SolidLink text={accessToken && isAdd ? "مضاف للعربة" : "أضف للعربة"} newClass={"btn-pay-course btn-wish-list"} onHandleClick={() => isAdd ? handleRemoveCart(id) : handleAddToCart(id)} />}
                    {!isPurchase && isFree !== true && isMyOwn !== true && <SolidLink text={"شراء الآن"} newClass={"btn-pay-course"} onHandleClick={() => handleAddToCart(id, true, true)} />}
                </div>
            </div>
        </div>
    );
}

export default CardContent;