"use client"
import { useGetAllCoursesQuery } from "@/redux/features/api/courses/coursesApiSlice";
function usePopularCoursesSection() {
    let { data: coursesCards, error, isLoading } = useGetAllCoursesQuery<any>({ sort: "sold",order: "desc", limit: "9" });
    return {
        coursesCards:  coursesCards?.data?.courses,
        error:  error?.data?.error?.message as string,
        isLoading,
    };
}

export default usePopularCoursesSection;
