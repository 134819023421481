import { useGetStatisticsHomeQuery } from "@/redux/features/api/statistics/statisticsApiSlice";

const useHomeSummary = () => {
    const { data: statisticsHome, error, isLoading } = useGetStatisticsHomeQuery<any>({});
    return {
        statisticsHome: statisticsHome?.data?.statisticsFromHome,
        errorHome: error?.data?.error?.message as string,
        isLoadingHome: isLoading,
    }
}

export default useHomeSummary;