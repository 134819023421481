"use client";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import OutLineLink from "@/components/UI/common/buttonsOrLinks/OutLineLink";
import LargeCoursesCard from "@/components/UI/common/cards/LargeCoursesCard";
import TitleSection from "@/components/UI/common/titles/TitleSection";
import useRecentCoursesSection from "@/hooks/pages/home/sections/recentCoursesSection/useRecentCoursesSection";
import getPrice from "@/utils/country/getPrice";
import ServerError from "@/components/UI/common/textError/ServerError";
import LoadingEffect from "@/components/UI/common/loading/LoadingEffect";
import useCountryUser from "@/hooks/common/useCountryUser";

function RecentCoursesSection() {
    const {
        coursesCards,
        isLoading,
        error,
        refBox,
        refItem,
        activeIndex,
        smooth,
        handleLeftClick,
        handleRightClick,
        handleBulletClick,
        handleActive,
    } = useRecentCoursesSection();

    const { userCountry } = useCountryUser();

    return (
        <section id="recent-courses-section">
            <div className="container">
                <div className="recent-top">
                    <div className="recent-top-left">
                        <TitleSection
                            title={"أحدث الدورات"}
                            newClass={"recent-header"}
                        />
                        <p className="recent-paragraph">
                            قم بتوسيع معرفتك مع هذه الدورات الجديدة.
                        </p>
                    </div>
                    <div className="recent-top-right">
                        <OutLineLink
                            link={"/courses?sort=createdAt&order=desc"}
                            text={"عرض الكل"}
                            newClass={"recent-view-all"}
                        />
                    </div>
                </div>
                {isLoading && <LoadingEffect />}
                {error && <ServerError errServerMsg={error} />}
                {Array.isArray(coursesCards) && coursesCards.length === 0 && <p className="no-course-hint">لم يتم إضافة دورات حتي الآن</p>}
                {coursesCards && Array.isArray(coursesCards) && <div
                    className={`recent-middle ${smooth} ${coursesCards.length <= 3 ? "larg-dir" : ""}`}
                    ref={refBox}
                    onScroll={handleActive}
                >
                    {Array.isArray(coursesCards) && coursesCards.length > 0 &&
                        [...coursesCards].reverse().map((el, inx) => (
                            <LargeCoursesCard
                                forwardRef={refItem}
                                key={el?.id + inx}
                                id={el?.id}
                                image={el?.thumbnailUrl}
                                title={el?.title}
                                chapters={el?._count?.chapter}
                                hours={el?.hours}
                                averageRatings={+el?.averageRatings?.toFixed(1)}
                                price={getPrice(el?.originalPriceInEGP, el?.originalPriceInUSD, userCountry)}
                                priceAfterDiscount={getPrice(el?.originalPriceInEGP - el?.discountAmountIsInEGP, el?.originalPriceInUSD - el?.discountAmountIsInUSD, userCountry) === getPrice(el?.originalPriceInEGP, el?.originalPriceInUSD, userCountry) ? "" : getPrice(el?.originalPriceInEGP - el?.discountAmountIsInEGP, el?.originalPriceInUSD - el?.discountAmountIsInUSD, userCountry)}
                                isFree={el?.isFree}
                                category={el?.category?.name}
                            />
                        ))}
                </div>}


                {Array.isArray(coursesCards) && coursesCards.length > 2 &&
                    <div className="recent-bottom">
                        <IoIosArrowBack
                            className={"arrow arrow-left"}
                            size={37}
                            onClick={handleLeftClick}
                        />

                        <div className="bullets-box">
                            {coursesCards && Array.isArray(coursesCards) &&
                                coursesCards.map((el, inx: number) => (
                                    <div
                                        key={inx}
                                        className={`bullets-item ${inx === activeIndex ? "active" : ""
                                            }`}
                                        onClick={(e) => handleBulletClick(inx)}
                                    ></div>
                                ))}
                        </div>
                        <IoIosArrowForward
                            className={"arrow arrow-right"}
                            size={37}
                            onClick={handleRightClick}
                        />
                    </div>}
            </div>
            <OutLineLink
                link={"/courses"}
                text={"عرض الكل"}
                newClass={"recent-view-all-bottom"}
            />
        </section>
    );
}

export default RecentCoursesSection;
