import { apiStatisticsSlice } from "@/redux/app/api/apiSlice";

export const statisticsApiSlice = apiStatisticsSlice.injectEndpoints({
    endpoints: (builder) => ({
        // anonymous
        getStatisticsHome: builder.query({
            query: (params) => ({
                url: `/api/v1/statistics/home`,
                params: { ...params },
            }),
        }),
        // admin
    }),
});

export const { useGetStatisticsHomeQuery } = statisticsApiSlice;
