import { useGetAllCategoryQuery } from "@/redux/features/api/categories/categoriesApiSlice";

function useCategoriesSection() {
    const { data: categoriesItems, error, isLoading } = useGetAllCategoryQuery<any>({ page: 1, limit: 3 });

    return {
        categoriesItems: categoriesItems?.data?.categories,
        error: error?.data?.error?.message as string,
        isLoading
    };
}

export default useCategoriesSection;