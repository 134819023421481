import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OpinionsCard from '../cards/OpinionsCard';
import avatarUserImg from "@/../public/images/avatur-user2.png";

interface VerticalSliderProps {
  slidesData: any[] | undefined;
  dir?: string;
  showNum?: number;
}

const VerticalSlider = ({ slidesData, dir, showNum }: VerticalSliderProps) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: showNum ? showNum : 2,
    slidesToScroll: dir === "toBottom" ? -1 : 1,
    vertical: true,
    rtl: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true,
    // swipeToSlide: true,
    // onSwipe: (direction: any) => {
    //   console.log("direction", direction)
    //   if (direction === "down") {
    //     // slider.slickPrev();
    //     slider.slickNext();
    //   } else if (direction === "up") {
    //     // slider.slickNext();
    //     slider.slickPrev();
    //   }
    // },
  };
  let slider: any;

  return (
    <>
      {
        Array.isArray(slidesData) && slidesData.length > 2 ?
          <Slider {...settings} ref={(c) => (slider = c)}>
            {
              slidesData.map((el, inx) => (
                <div key={el?.id + inx} className='wrap-opinion-cart-slick'>
                  <OpinionsCard
                    id={el?.id}
                    name={el?.user?.username}
                    description={el?.user?.country}
                    quote={el?.comment}
                    rating={el?.rating}
                    image={el?.profileImageUrl || avatarUserImg}
                    cursorGrab={true}
                  />
                </div>
              ))
            }
          </Slider>
          :
          <div>
            {Array.isArray(slidesData) && slidesData.map((el, inx) => (
              <div key={el?.id + inx} className='wrap-opinion-cart-slick'>
                <OpinionsCard
                  id={el?.id}
                  name={el?.user?.username}
                  description={el?.user?.country}
                  quote={el?.comment}
                  rating={el?.rating}
                  image={el?.profileImageUrl || avatarUserImg}
                  cursorGrab={false}
                />
              </div>
            ))}
          </div>
      }
    </>
  );
};

export default VerticalSlider;