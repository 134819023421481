"use client";
import { useRef, useEffect, useState } from "react";
import scrollToSmooth from "@/utils/jsDom/scrollSmooth";
import { useGetAllCoursesQuery } from "@/redux/features/api/courses/coursesApiSlice";
function useRecentCoursesSection() {
    let { data: coursesCards, error, isLoading } = useGetAllCoursesQuery<any>({ sort: "createdAt" });
    const refBox = useRef<HTMLDivElement>(null);
    const refItem = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [smooth, setSmooth] = useState("");
    const gap = 20;
    useEffect(() => {
        handleActive();
        if (coursesCards?.data?.courses && refBox && refItem && refBox.current && refItem.current) {
            refBox.current.scrollLeft = refBox.current.scrollWidth;
            setSmooth("smooth");
            handleActive();
        }
    }, [coursesCards, coursesCards?.data?.courses, isLoading, refBox.current]);

    const handleLeftClick = () => {
        if (refBox && refItem && refBox.current && refItem.current) {
            scrollToSmooth(refBox.current, refBox.current.scrollLeft - refItem.current.offsetWidth - gap, 500);

        }
    };
    const handleRightClick = () => {
        if (refBox && refItem && refBox.current && refItem.current) {
            scrollToSmooth(refBox.current, refBox.current.scrollLeft + refItem.current.offsetWidth + gap, 500);
        }
    };
    const handleBulletClick = (inx: number) => {
        if (refBox && refItem && refBox.current && refItem.current) {
            scrollToSmooth(refBox.current, inx * refItem.current.offsetWidth + (inx - 1) * gap, 500);

        }
    };
    const handleActive = () => {
        if (coursesCards?.data?.courses && refBox && refItem && refBox.current && refItem.current) {
            for (let x = 0; x < coursesCards?.data?.courses.length; x++) {
                if (
                    refItem.current.offsetWidth * x <=
                    refBox.current.scrollLeft +
                    0.5 * refItem.current.offsetWidth &&
                    refItem.current.offsetWidth * x >=
                    refBox.current.scrollLeft -
                    0.6 * refItem.current.offsetWidth
                ) {
                    setActiveIndex(x);
                }
            }
        }
    };

    const [activeMobile, setActiveMobile] = useState(false);
    var x = typeof window !== "undefined" && window.matchMedia("(max-width: 767.98px)");

    x && x.addEventListener("change", function () {
        if (x && x.matches) {
            setActiveMobile(true);
        } else {
            setActiveMobile(false);
        }
    });

    return {
        coursesCards: coursesCards?.data?.courses,
        error: error?.data?.error?.message as string,
        isLoading,
        activeMobile,
        refBox,
        refItem,
        activeIndex,
        setActiveIndex,
        smooth,
        setSmooth,
        gap,
        handleLeftClick,
        handleRightClick,
        handleBulletClick,
        handleActive,
    };
}

export default useRecentCoursesSection;