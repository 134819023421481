"use client"
import OutLineLink from "@/components/UI/common/buttonsOrLinks/OutLineLink";
import LargeCoursesCard from "@/components/UI/common/cards/LargeCoursesCard";
import TitleSection from "@/components/UI/common/titles/TitleSection";
import usePopularCoursesSection from "@/hooks/pages/home/sections/popularCoursesSection/usePopularCoursesSection";
import getPrice from "@/utils/country/getPrice";
import ServerError from "@/components/UI/common/textError/ServerError";
import LoadingEffect from "@/components/UI/common/loading/LoadingEffect";
import { useAppSelector } from "@/redux/app/hooks";

function PopularCoursesSection() {
    const { coursesCards, error, isLoading } = usePopularCoursesSection();
    const userCountry = useAppSelector(store => store.userCountry.userCountry);

    return (
        <section id="popular-courses-section">
            <div className="container">
                <div className="popular-top">
                    <TitleSection
                        title={"أشهر الدورات"}
                        newClass={"popular-title"}
                    />
                    <p className="popular-description">
                        استكشف مجموعة واسعة من الدورات المشهورة لدينا سجل معنا
                        اليوم
                    </p>
                </div>
                <div className="popular-middle">
                    {isLoading && <LoadingEffect />}
                    {error && <ServerError errServerMsg={error} />}
                    {Array.isArray(coursesCards) && coursesCards.length === 0 && <p className="no-course-hint">لم يتم إضافة دورات حتي الآن</p>}
                    {Array.isArray(coursesCards) &&
                        coursesCards.map((el, inx) => (
                            <LargeCoursesCard
                                key={el?.id + inx}
                                id={el?.id}
                                image={el?.thumbnailUrl}
                                title={el?.title}
                                chapters={el?._count?.chapter}
                                hours={el?.hours}
                                averageRatings={+el.averageRatings?.toFixed(1)}
                                price={getPrice(el?.originalPriceInEGP, el?.originalPriceInUSD, userCountry)}
                                priceAfterDiscount={getPrice(el?.originalPriceInEGP - el?.discountAmountIsInEGP, el?.originalPriceInUSD - el?.discountAmountIsInUSD, userCountry) === getPrice(el?.originalPriceInEGP, el?.originalPriceInUSD, userCountry) ? "" : getPrice(el?.originalPriceInEGP - el?.discountAmountIsInEGP, el?.originalPriceInUSD - el?.discountAmountIsInUSD, userCountry)}
                                isFree={el?.isFree}
                                category={el?.category?.name}
                            />
                        ))}
                </div>
                <div className="popular-bottom">
                    <OutLineLink
                        link={"/courses?sort=sold&createdAt=desc"}
                        text={"عرض الكل"}
                        newClass={"popular-view-all"}
                    />
                </div>
            </div>
        </section>
    );
}

export default PopularCoursesSection;
