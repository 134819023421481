"use client"
import LoadingEffect from "@/components/UI/common/loading/LoadingEffect";
import ServerError from "@/components/UI/common/textError/ServerError";
import TitleSection from "@/components/UI/common/titles/TitleSection";
import VerticalSlider from "@/components/UI/common/verticalSlider/VerticalSlider";
import useMobileScreen from "@/hooks/functions/useMobileScreen";
import useOpinionsSection from "@/hooks/pages/home/sections/OpinionsSection/useOpinionsSection";
import divideArray from "@/utils/limitsQuery/divideArray";

function OpinionsSection() {
    const { activeMobile } = useMobileScreen();
    const { opinionsUsers, error, isLoading } = useOpinionsSection();

    return (
        <section id={"opinions-section"}>
            {isLoading && <LoadingEffect />}
            {error && <ServerError errServerMsg={error} />}
            {Array.isArray(opinionsUsers) && opinionsUsers.length > 0 &&
                <div className="container">
                    <TitleSection
                        title={"طلابنا قالوا عنا"}
                        newClass={"opinions-title"}
                    />
                    {
                        !activeMobile && Array.isArray(opinionsUsers) &&
                        <div className="opinions-box-home">
                            <VerticalSlider slidesData={divideArray(opinionsUsers)[0]} />
                            <VerticalSlider slidesData={divideArray(opinionsUsers)[1]} dir="toBottom" />
                            <VerticalSlider slidesData={divideArray(opinionsUsers)[2]} />
                        </div>
                    }
                    {
                        activeMobile && Array.isArray(opinionsUsers) &&
                        <div className="opinions-box-home">
                            <VerticalSlider slidesData={opinionsUsers} showNum={opinionsUsers.length > 4 ? 4 : opinionsUsers.length === 1 ? 1 : opinionsUsers.length - 1} />
                        </div>
                    }
                </div>
            }
        </section>
    );
}

export default OpinionsSection;