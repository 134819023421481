"use client"
import TitleSection from "@/components/UI/common/titles/TitleSection";
import { TbClockHour5 } from "react-icons/tb";
import { PiStudent } from "react-icons/pi";
import { PiBooksLight } from "react-icons/pi";
import useHomeSummary from "@/hooks/pages/dashbord/admin/adminSummarySections/useHomeSummary";
import { FiUsers } from "react-icons/fi";
import LoadingEffect from "@/components/UI/common/loading/LoadingEffect";
import ServerError from "@/components/UI/common/textError/ServerError";

function StatisticsSection() {
    const { statisticsHome, errorHome, isLoadingHome } = useHomeSummary();
    return (
        <section id="statistics-section">
            {isLoadingHome && <LoadingEffect />}
            {errorHome && <ServerError errServerMsg={errorHome} />}
            {statisticsHome && <div className="container">
                <TitleSection
                    title={"أفضل الإحصائيات"}
                    newClass={"statistics-title-section"}
                />
                <div className="statistics box">
                    <div className="statistics-item hours">
                        <FiUsers className={"statistics-icon"} />
                        <span className="statistics-numbers">{statisticsHome?.totalNumberOfUsers || 0}</span>
                        <h3 className="statistics-title">مستخدمين</h3>
                    </div>
                    <div className="statistics-item students">
                        <PiStudent className={"statistics-icon"} />
                        <span className="statistics-numbers">{statisticsHome?.totalNumberOfVideos || 0}</span>
                        <h3 className="statistics-title"> فيديو تعليمي</h3>
                    </div>
                    <div className="statistics-item courses">
                        <PiBooksLight className={"statistics-icon"} />
                        <span className="statistics-numbers">{statisticsHome?.totalNumberOfCourse || 0}</span>
                        <h3 className="statistics-title">دورة تعليمية</h3>
                    </div>
                </div>
            </div>}
        </section>
    );
}

export default StatisticsSection;
